<template>
  <div>
    <el-row class="cols--center mar-v--1">
      <div class="cell"></div>

      <div name="table-pagination" class="table-pagination pull-right mar-v--1">
        <table-navigation :state="state" :change-handler="load"></table-navigation>
      </div>
    </el-row>

    <el-table
      :name="model.name + '-table'"
      :class="model.name + '-table'"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      v-loading="state.loading"
    >
      <el-table-column prop="id" :label="$tf('id')" width="60"></el-table-column>

      <el-table-column prop="thumbnail" :label="$tf('common.detected')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail
            :url="row.thumbnail || ''"
            :frame="row.source_photo || ''"
            @preview="(v) => showImage(row.source_photo, getBox(row))"
          ></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column>
        <template slot-scope="{ row }">
          <div class="mar-v-1">
            <a name="search-cases-btn" :href="getSearchPath(row)" target="_blank">
              <i class="mar-left--1 el-icon-search"></i> {{ $tfo('fast_case_search') }}
            </a>

            <div class="case-face__action-buttons">
              <el-button size="small" @click="() => searchHandler(row)">{{ $tfo('cases_search') }}</el-button>
              <el-button size="small" @click="() => searchCard(row)">{{ $tfo('card_search') }}</el-button>
              <el-button v-if="$store.getters.puppeteer" size="small" @click="() => searchExternal(row)">{{ $tf('external | search') }}</el-button>
            </div>

            <div class="mar-v--1"></div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$tf('video_archive')" width="120">
        <template slot-scope="{ row }">
          <span v-if="hasVideos">{{ getVideoName(row) }}</span> <span>(id: {{ row.video_archive }})</span>
        </template>
      </el-table-column>

      <el-table-column width="120" :label="$tf('date')">
        <template slot-scope="{ row }">
          {{ row.created_date | parseDate | formatDate }}<br />
          {{ row.created_date | parseDate | formatTime }}<br />
        </template>
      </el-table-column>
    </el-table>

    <div name="table-pagination" class="table-pagination pull-right mar-v--1">
      <table-navigation :state="state" :change-handler="load"></table-navigation>
    </div>
  </div>
</template>
<script>
import _ from '@/apps/common/lodash';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import qs from 'qs';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  name: 'page-daily-events',
  components: { ObjectThumbnail, TableNavigation },
  props: {
    caseId: {
      type: String,
      required: true
    },
    videoFilter: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      model: {
        name: 'case-faces',
        route: 'case-faces'
      },
      videos: []
    };
  },
  computed: {
    hasVideos() {
      return this.videos.length > 0;
    },
    state() {
      return this.$store.state.case_faces;
    }
  },
  created() {
    this.state.items = [];
    this.state.filter.current.case_in = [this.caseId];
  },
  mounted() {
    this.loadVideos();
  },
  methods: {
    async loadVideos() {
      const response = await this.$store.dispatch(this.$store.state.videos.Action.Get, { filter: this.videoFilter });
      this.videos = response.results;
    },
    load() {
      this.$store.dispatch(this.state.Action.Get).catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });
    },
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return `/${this.model.route}/` + encodeURIComponent(item.id) + '/';
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(v) {
      return {
        x: v.source_coords_left,
        y: v.source_coords_top,
        w: v.source_coords_right - v.source_coords_left,
        h: v.source_coords_bottom - v.source_coords_top
      };
    },
    getVideoName(item) {
      return this.videos.find(({ id }) => id === item.video_archive)?.name ?? '...';
    },
    getSearchPath(row) {
      const confidenceThreshold = this.$store.getters.objectConfidenceThresholds.face_confidence_threshold;
      return `#/cases/filter/limit=10&looks_like=caseface:${row.id}&threshold=${confidenceThreshold}`;
    },
    getCardSearchPath(row) {
      const filter = {
          ..._.cloneDeep(this.$store.state.search_objects.filter.empty),
          looks_like: `caseface:${row.id}`,
          targetType: 'cards',
          sourceType: 'caseface',
          threshold: this.$store.getters.face_confidence_threshold
        },
        searchFilterString = qs.stringify(
          _.pickBy(filter, (v, k) => !!v),
          { sort: alphabeticalSort }
        );
      return `/search/${searchFilterString}`;
    },
    searchCard(item) {
      this.$router.push({ path: this.getCardSearchPath(item) });
    },
    searchExternal(item) {
      this.$emit('search-external', item);
    },
    searchHandler(item) {
      this.$emit('search', item);
    }
  }
};
</script>

<style lang="stylus">
.case-face__action-buttons {
  .el-button {
    margin-top: 0.5rem;
  }

  .el-button + .el-button {
    margin-left: 0.5rem;
  }
}
</style>
